import React, { useState, useEffect, lazy} from "react";
import { Container, Row, Col } from "react-bootstrap";

import SEO from "../../components/seo";
import api from "../../actions/riveroapi";
import PaginationApi from "../../components/paginacion/PaginacionApi";

const Layout = lazy(() => import('../../components/layouts/layout'))
const FrameFacebook = lazy(() => import('../../components/widgets/FrameFacebook'))
const CatalogoLayoutBlogs = lazy(() => import('../../components/layouts/CatalogoLayoutBlogs'))

export default function IndexBlogs(props){
    const parameters = props.location.search;

    const [blogs, setBlogs] = useState([]);
    const [metaData, setMetaData] = useState(null); 
    const [isLoading, setIsLoading] = useState(true);
    const [ links, setLinks ] = useState(null);
    const path = props.location.pathname.split("/");
    const [pageNumber, setPageNumber] = useState(path[2] ? parseInt(path[2]) : 1);

    const [ splitMarca, setSplitMarca ] = useState();
    console.log("path: ", path);
    
    useEffect(() => {
        let splitUrl = window.location.pathname.split("/");
        console.log("SplitUtl, ", splitUrl[1]);
        setSplitMarca(splitUrl[1]);
        setIsLoading(true);
        api.getBlogs({ page: pageNumber, orderBy: "fecha:desc", marca: splitUrl[1] }).then((resp) => {
            console.log(resp.data);
            setIsLoading(false);
            setMetaData(resp.meta);
            setBlogs(resp.data);
            setLinks(resp.links)
        }).catch((error) => console.log(error));

    }, [pageNumber]);

    return(
        <Layout parameters={parameters}>
            <SEO
                title="Grupo Rivero Chevrolet Monterrey - Entradas del blog"
                description={
                "Grupo Rivero - Agencia Chevrolet Monterrey  Aveo, Beat, " +
                "Spark, Onix, Cavalier, Trax - venta autos nuevos y " +
                "seminuevos - Chevrolet Guadalupe, Santa Catarina, LindaVista"
                }
                keywords="Agencia Chevrolet Monterrey"
                path={`/blog`}
            />
            <Container className="p-4 bg-white">
                <Row>
                    <Col lg={8} sm={12} md={8}>
                        <h1>Entradas del blog</h1>
                        <div className="pt-4">
                            <CatalogoLayoutBlogs post={blogs} isLoading={isLoading} marca={'chevrolet'}/>
                            <hr/>
                            <PaginationApi
                                metaData={metaData}
                                links={links}
                                pagina="rivero/blogs"
                                onChangePage={setPageNumber}
                                pageNumber={pageNumber}
                            />
                        </div>
                    </Col>
                    <Col lg={4} sm={12} md={3}>
                        <FrameFacebook marca={'chevrolet'}/>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )

}